/*global window, document, jQuery*/

/* Author:
    Marek Mrowiec m@crevi.pl
*/

const HELION = HELION || {};

HELION.init = function () {
  this.menu();
  this.images();
  this.cookieDetect();
  this.currentRoute();
  this.changeMeta();
};

HELION.resize = function () {
  this.images();
};
HELION.currentRoute = () => {
  if (location.pathname !== "/") {
    $("a[href*='" + location.pathname + "']").addClass("active");
  }
};
HELION.changeMeta = () => {
  const fullpath = window.location.href;
  const pathName = /[^/]*$/.exec(window.location.pathname)[0].replace("-", " ") || "EduDziennik";
  const setMeta = `${pathName[0].toUpperCase() + pathName.slice(1)} | EduDziennik.pl`;
  $("meta[property='og\\:title']").attr("content", setMeta);
  $("meta[property='og\\:url']").attr("content", fullpath);
  $("meta[name='twitter\\:title']").attr("content", setMeta);
};

HELION.menu = function () {
  // const width = window.innerWidth;

  // if(width <= 1024) {
  //     $('.js-menu').on('click', function(e) {
  //         e.preventDefault();
  //         $(this).parent().siblings().find('.js-submenu').hide();
  //         $(this).siblings('.js-submenu').toggle();
  //     });
  // }

  $(".js-menu-trigger").on("click", function () {
    $(this).toggleClass("active");
    $(".js-menu-box").fadeToggle();
  });
};

HELION.images = function () {
  const width = window.innerWidth;
  $(".js-img").each(function () {
    var $image = $(this),
      img = $image.data("src"),
      imgmobile = $image.data("srcMoblie");
    if (width > 767) {
      $image.attr("src", img);
    } else {
      $image.attr("src", imgmobile);
    }
  });
};

HELION.cookieDetect = () => {
  if ($(".js-cookies .js-cookies-close").length <= 0) {
    if (!localStorage.getItem("educookie")) {
      $(".js-cookies").append(
        '<div class="container"><p class="cookies__text">Ta strona używa ciasteczek (cookies), aby działać lepiej. <a href="/polityka-prywatnosci#polityka-cookies" class="cookies__link">Dowiedz się więcej</a>.</p> <button class="btn btn--color-1 cookies__btn js-cookies-agree">rozumiem</button></div>'
      );
      $(".js-cookies").show();

      $(".js-cookies").on("click", ".js-cookies-agree", (e) => {
        $(".js-cookies").fadeOut();
        e.preventDefault();
        localStorage.setItem("educookie", "true");
      });
    }
  }
};
jQuery(function () {
  HELION.init();
});

jQuery(window).resize(function () {
  HELION.resize();
});
